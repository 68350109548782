import { Button as ChakraButton, type ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import { forwardRef, type ReactNode } from 'react';

import mixpanel from '~sf/vendor/mixpanel';

export type ButtonProps = {
  children?: ReactNode;
  eventName: string;
  eventProps?: { [key: string]: string | number | boolean };
} & ChakraButtonProps;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, eventName, eventProps, onClick, ...rest }: ButtonProps, ref) => {
    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
      mixpanel.track(`click ${eventName}`, { ...eventProps });
      if (onClick) {
        onClick(e);
      }
    };

    return (
      <ChakraButton ref={ref} onClick={onClickHandler} {...rest}>
        {children}
      </ChakraButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
