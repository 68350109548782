import * as Sentry from '@sentry/react';
import axios from 'axios';
import { Fetcher, type Middleware } from 'openapi-typescript-fetch';

// eslint-disable-next-line no-restricted-syntax
import type { ApiPaths, UcsfApiModels, UcsfApiPaths } from '.';

export const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:8000';

// eslint-disable-next-line no-restricted-syntax
export const fetcher = Fetcher.for<ApiPaths & UcsfApiPaths>();

fetcher.configure({
  baseUrl: API_URL,
});

export const TOKEN_STORAGE_KEY = 'token';

export const updateRequestHeaders = (newToken?: string | null) => {
  fetcher.configure({
    baseUrl: API_URL,
    init: {
      headers: {
        Authorization: newToken || '',
      },
    },
  });

  // eslint-disable-next-line functional/immutable-data
  axios.defaults.headers.common.Authorization = newToken;
};

export const setAuthToken = (newToken: string) => {
  const tokenStr = newToken.startsWith('Token ') ? newToken : `Token ${newToken}`;
  localStorage.setItem(TOKEN_STORAGE_KEY, tokenStr);

  updateRequestHeaders(tokenStr);
};

export const unsetAuthToken = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);

  updateRequestHeaders();
  Sentry.setUser(null);
};

export const addFetchMiddleware = (middleware: Middleware) => {
  fetcher.use(middleware);
};

export function setupFetcher() {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);

  if (token) {
    updateRequestHeaders(token);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-restricted-syntax
export const isFailedResponse = (e: any): e is UcsfApiModels['FailedResponse'] =>
  'fields' in e && 'message' in e;
