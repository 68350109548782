import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { ENV } from '~sf/constants';

const IGNORED_ENVS = ['local', 'test'];

export const setupSentry = () => {
  const dsn = import.meta.env.VITE_SENTRY_DSN;

  if (!dsn) {
    return;
  }

  Sentry.init({
    dsn,
    enabled: !IGNORED_ENVS.includes(ENV),
    environment: ENV,
    release: import.meta.env.VITE_CODE_VERSION,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 0.1,

    // Session replays
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,

    beforeSend: filterSentryEvents,
  });
};

const filterSentryEvents: Sentry.BrowserOptions['beforeSend'] = (event, hint) => {
  const error = hint.originalException as Error | undefined;

  // Ignore uPlot noisy exceptions
  if (
    error?.message &&
    error.stack?.match(/uplot/giu) &&
    (error.message.match(/cannot read properties of undefined/giu) ||
      error.message.match(/undefined is not an object/giu))
  ) {
    return null;
  }

  return event;
};
