// Left-most version identifier - in case we ever want to reset all other versions
export const APP_MAJOR_VERSION = 2;

export const ENV = import.meta.env.VITE_ENV_NAME || 'test';

export const QUERY_REFETCH_TIMEOUT_SHORT = 120 * 1000;
export const QUERY_REFETCH_TIMEOUT_LONG = 60 * 1000;

export const MIXPANEL_TOKEN = '909b0b6454069f0adf862c427faa6555';
export const TRACKED_ENVIRONMENTS = ['production', 'demo'];

export const KARDIA_APP_IOS = 'https://apps.apple.com/us/app/kardia/id579769143';
export const KARDIA_APP_ANDROID =
  'https://play.google.com/store/apps/details?id=com.alivecor.aliveecg';

export const MYLUX_APP_IOS = 'https://apps.apple.com/us/app/mylux-patient-app/id1501560462';
export const MYLUX_APP_ANDROID =
  'https://play.google.com/store/apps/details?id=com.bsci.latitude.icm.mobilemonitor.android.mylux';

export const DOCUMENT_IFU =
  'https://safebeat-static-files.s3.us-west-1.amazonaws.com/Instructions+for+Use.pdf';
export const DOCUMENT_PRIVACY_POLICY =
  'https://safebeat-static-files.s3.us-west-1.amazonaws.com/Privacy+Policy.pdf';
export const DOCUMENT_TERMS_OF_USE =
  'https://safebeat-static-files.s3.us-west-1.amazonaws.com/Terms+of+Use.pdf';

export const GTIN = '00850043189107';
