import { fetcher } from '~sf/api/fetch';

// ---------------------------------------------------
//              Export UCSF-only Clients
// ---------------------------------------------------
// eslint-disable-next-line no-restricted-syntax
export * from './ucsf';
// ---------------------------------------------------

// ---------------------------------------------------
//              Export Custom Clients
// ---------------------------------------------------
export * from './custom';
// ---------------------------------------------------

// API Info
export const apiInfoClient = fetcher.path('/').method('get').create();

// Auth
export const loginClient = fetcher.path('/web-client/auth/login/password').method('post').create();
export const loginLinkClient = fetcher
  .path('/web-client/auth/login/one-time-password/initiate')
  .method('post')
  .create();
export const loginLinkValidateClient = fetcher
  .path('/web-client/auth/login/one-time-password')
  .method('post')
  .create();
export const logoutClient = fetcher.path('/web-client/auth/logout').method('post').create();
export const profileClient = fetcher.path('/web-client/auth/profile/me').method('get').create();
export const entitlementsClient = fetcher
  .path('/web-client/auth/entitlement/me')
  .method('get')
  .create();

// Clinic
export const clinicClient = fetcher.path('/web-client/clinic/v2/').method('get').create();
export const createClinicClient = fetcher.path('/web-client/clinic/v2/').method('post').create();
export const editClinicClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/')
  .method('put')
  .create();
export const getClinicDataClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/')
  .method('get')
  .create();
export const deleteClinicDataClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/')
  .method('delete')
  .create();

export const createStaffClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/staff/')
  .method('post')
  .create();
export const getStaffListClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/staff/')
  .method('get')
  .create();
export const getAllStaffClient = fetcher
  .path('/web-client/clinic/v2/staff/all/')
  .method('get')
  .create();
export const getStaffClient = fetcher
  .path('/web-client/clinic/v2/staff/{user_id}/')
  .method('get')
  .create();
export const updateStaffClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/staff/{user_id}/')
  .method('put')
  .create();
export const deleteStaffClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/staff/{user_id}/')
  .method('delete')
  .create();

// Patients
export const getAllPatientsClient = fetcher
  .path('/web-client/clinic/v2/patient/all/')
  .method('get')
  .create();
export const getPatientsListClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/patient/')
  .method('get')
  .create();
export const createPatientClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/patient/')
  .method('post')
  .create();
export const createAnonymousPatientClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/anonymous-patient/')
  .method('post')
  .create();
export const updatePatientClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/patient/{patient_id}/')
  .method('put')
  .create();
export const updateAnonymousPatientClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/anonymous-patient/{patient_id}/')
  .method('put')
  .create();

export const getPatientClient = fetcher
  .path('/web-client/clinic/v2/patient/{patient_id}/')
  .method('get')
  .create();
export const deletePatientClient = fetcher
  .path('/web-client/clinic/v2/patient/{patient_id}/delete/')
  .method('delete')
  .create();

export const getPatientActionLog = fetcher
  .path('/web-client/action-log/patient/{patient_id}/actions/')
  .method('get')
  .create();

// Providers
export const editProviderClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/staff/{user_id}/')
  .method('put')
  .create();
export const getProviderClient = fetcher
  .path('/web-client/clinic/v2/staff/{user_id}/')
  .method('get')
  .create();
export const deleteProviderClient = fetcher
  .path('/web-client/clinic/v2/{clinic_id}/staff/{user_id}/')
  .method('delete')
  .create();

// forgotpassword
export const forgotPasswordClient = fetcher
  .path('/web-client/auth/change-password/one-time-password/initiate')
  .method('post')
  .create();
export const resetPasswordClient = fetcher
  .path('/web-client/auth/change-password/one-time-password')
  .method('post')
  .create();

// contact clinic
export const contactClient = fetcher.path('/web-client/contact/').method('post').create();

// Annotation Portal
export const getAnnotationJobsClient = fetcher
  .path('/web-client/annotation-portal/annotation-job/')
  .method('get')
  .create();
export const getAnnotationJobsAssignedClient = fetcher
  .path('/web-client/annotation-portal/user-assigned-annotation-job/me')
  .method('get')
  .create();
export const getAnnotationTasksClient = fetcher
  .path('/web-client/annotation-portal/annotation-job/{annotation_job_id}/tasks/receive')
  .method('patch')
  .create();
export const getAnnotationTaskByFilenameClient = fetcher
  .path('/web-client/annotation-portal/annotation-job/{annotation_job_id}/tasks/receive-single')
  .method('patch')
  .create();
export const markAnnotationTaskUnreadableClient = fetcher
  .path('/web-client/annotation-portal/annotation-task/{annotation_task_id}/mark-unreadable')
  .method('post')
  .create();
export const saveAnnotationTaskResultClient = fetcher
  .path('/web-client/annotation-portal/annotation-task/{annotation_task_id}/result')
  .method('post')
  .create();

export const needsReviewAnnotationTaskResultClient = fetcher
  .path('/web-client/annotation-portal/annotation-task/{annotation_task_id}/needs-review')
  .method('post')
  .create();

export const getAnnotationTaskHistoryClient = fetcher
  .path('/web-client/annotation-portal/annotation-task/{annotation_task_id}/history')
  .method('get')
  .create();

export const getJobAnnotatorsClient = fetcher
  .path(
    '/web-client/annotation-portal/annotation-job/{annotation_job_id}/originating-result-annotators',
  )
  .method('get')
  .create();

// SCAN Drug loading protocol
export const getDrugLoadingProtocolClient = fetcher
  .path('/web-client/drug-loading-protocol/patient/{patient_id}/latest/')
  .method('get')
  .create();

export const initiateDrugLoadingProtocolClient = fetcher
  .path('/web-client/drug-loading-protocol/patient/{patient_id}/initiate/')
  .method('post')
  .create();

export const endDrugLoadingProtocolClient = fetcher
  .path('/web-client/drug-loading-protocol/{drug_loading_protocol_id}/end/')
  .method('patch')
  .create();

export const extendDrugLoadingProtocolClient = fetcher
  .path('/web-client/drug-loading-protocol/{drug_loading_protocol_id}/extend/')
  .method('patch')
  .create();

export const reopenDrugLoadingProtocolClient = fetcher
  .path('/web-client/drug-loading-protocol/{drug_loading_protocol_id}/reopen/')
  .method('patch')
  .create();

export const getProtocolTimelineClient = fetcher
  .path('/web-client/drug-loading-protocol/{drug_loading_protocol_id}/timeline/')
  .method('get')
  .create();

export const getEcgBundleSelectedRecordingClient = fetcher
  .path(
    '/web-client/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/selected-recording-w-analysis/',
  )
  .method('get')
  .create();

export const getAllEcgsInBundle = fetcher
  .path(
    '/web-client/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/all-recordings-w-analysis/',
  )
  .method('get')
  .create();

export const releaseEcgBundleClient = fetcher
  .path('/web-client/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/release/')
  .method('patch')
  .create();

export const setSelectedEcgRecordingClient = fetcher
  .path(
    '/web-client/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/ecg-recording/{ecg_recording_id}/selected/',
  )
  .method('patch')
  .create();

export const unReleaseEcgBundleClient = fetcher
  .path('/web-client/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/hold/')
  .method('patch')
  .create();

export const approveEcgBundleClient = fetcher
  .path('/web-client/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/approve/')
  .method('post')
  .create();

export const unApproveEcgBundleClient = fetcher
  .path(
    '/web-client/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/approval/',
  )
  .method('delete')
  .create();

export const rejectEcgRecordingClient = fetcher
  .path('/web-client/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/reject/')
  .method('post')
  .create();

export const saveAnalysisAndDecisionClient = fetcher
  .path('/web-client/ecg-analysis/ecg-measurement-recording/{ecg_recording_id}/analysis/')
  .method('post')
  .create();

export const approveMedicationDosageClient = fetcher
  .path('/web-client/drug-loading-protocol/medication-dosage/{medication_dosage_id}/approval/')
  .method('post')
  .create();

export const unApproveMedicationDosageClient = fetcher
  .path(
    '/web-client/drug-loading-protocol/medication-dosage/approval/{medication_dosage_approval_id}/',
  )
  .method('delete')
  .create();

export const getManualMeasurementsClient = fetcher
  .path('/web-client/manual-patient-measurement/patient/{patient_id}/latest_for_kinds/')
  .method('get')
  .create();

export const saveManualMeasurementsClient = fetcher
  .path('/web-client/manual-patient-measurement/patient/{patient_id}/')
  .method('post')
  .create();

export const reportDoseTakenClient = fetcher
  .path(
    '/web-client/drug-loading-protocol/medication-dosage/approval/{medication_dosage_approval_id}/taken/',
  )
  .method('patch')
  .create();

export const getEcgPlacementOptionsClient = fetcher
  .path(
    '/web-client/drug-loading-protocol/{drug_loading_protocol_id}/available-upload-dosage-placements/',
  )
  .method('get')
  .create();

export const getNotificationLog = fetcher
  .path(
    '/web-client/notification/log/drug_loading_protocol/{drug_loading_protocol_id}/doctor-patient-sms/',
  )
  .method('get')
  .create();

export const notifyAdminsPatientActivity = fetcher
  .path('/web-client/notification/notify-admins/patient-activity/me')
  .method('post')
  .create();

export const updateEcgNotesClient = fetcher
  .path('/web-client/ecg-recording/ecg-recording/{ecg_recording_id}/notes/')
  .method('patch')
  .create();

// Devices
export const getDevicesClient = fetcher.path('/web-client/device/').method('get').create();
export const getDeviceClient = fetcher
  .path('/web-client/device/{device_id}/')
  .method('get')
  .create();

export const getPatientDevicesClient = fetcher
  .path('/web-client/device/patient/{patient_id}/')
  .method('get')
  .create();

export const createDeviceClient = fetcher.path('/web-client/device/').method('post').create();

export const updateDeviceClient = fetcher
  .path('/web-client/device/{device_id}/')
  .method('put')
  .create();

export const injectEcgSignalFileClient = fetcher
  .path(
    '/web-client/drug-loading-protocol/{drug_loading_protocol_id}/device/{device_id}/inject-prerecorded-ecg-signal-file/',
  )
  .method('post')
  .create();

// Patient Client
export const getPatientViewClient = fetcher
  .path('/web-client/patient/client/me')
  .method('get')
  .create();
export const patchPatientDoseTakenClient = fetcher
  .path(
    '/web-client/drug-loading-protocol/medication-dosage/approval/{medication_dosage_approval_id}/taken/me/',
  )
  .method('patch')
  .create();
