import useEntitlements from '~sf/utils/hooks/useEntitlements';
import useGetClinic from '~sf/utils/hooks/useGetClinic';

function useIsClinicalTrial(): boolean {
  const clinic = useGetClinic();
  const { canListPatients } = useEntitlements();

  if (!clinic || !canListPatients) {
    return false;
  }

  return (
    // Checking this to avoid impacting the old e2e tests
    clinic.execution_flow_type === 'scan_mvp' &&
    // eslint-disable-next-line no-restricted-syntax
    clinic.configuration.patient_client_flow === 'ucsf_trial'
  );
}

export default useIsClinicalTrial;
