import { Code, type CodeProps, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { apiInfoClient } from '~sf/api/clients';
import { QueryKey } from '~sf/api/query';
import { APP_MAJOR_VERSION } from '~sf/constants';

export const releaseVersion = import.meta.env.VITE_RELEASE_VERSION || '-';
export const releaseVersionClean = Number.parseInt(releaseVersion.replace('v', ''), 10) || 0;

type AppVersionProps = CodeProps;

const AppVersion = ({ ...rest }: AppVersionProps) => {
  const { data: apiInfo, isLoading } = useQuery({
    queryKey: QueryKey.ApiInfo,
    queryFn: async () => apiInfoClient({}),
  });

  if (isLoading) {
    return <Spinner size="sm" />;
  }

  return (
    <Code {...rest}>
      v
      {`${APP_MAJOR_VERSION}.${releaseVersionClean}.${
        apiInfo?.data.api_service.major_version_number ?? '-'
      }.${apiInfo?.data.ml_service.major_version_number ?? '-'}`}
    </Code>
  );
};

export default AppVersion;
