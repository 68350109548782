import type { TrendThresholds } from '~sf/components/ui/TrendLine';

export const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:8000';

// TODO: Move these to backend, in some sort of "clinic settings" API
export const QTcThresholds: TrendThresholds = {
  upper: 500,
  upperWarning: 460,
};

// Cutoff values for "realistic" QTc values
export const QTcMinMax = {
  min: 330,
  max: 600,
};

export const HRThresholds: TrendThresholds = {
  upper: 100,
  upperWarning: 90,
  lowerWarning: 60,
  lower: 50,
};

export const QRSThresholds: TrendThresholds = {
  upperWarning: 120,
};

export const PRThresholds: TrendThresholds = {
  upperWarning: 200,
};

export const CrClThresholds: TrendThresholds = {
  lowerWarning: 61,
  lower: 20,
};

export const NAME_MAX_LENGTH = 50;
export const NAME_MIN_LENGTH = 2;
export const PASSWORD_MAX_LENGTH = 50;
export const PASSWORD_MIN_LENGTH = 2;
export const PHONE_NO_MAX_LENGTH = 20;
export const ONE_HOUR = 60 * 60 * 1000; /* ms */
export const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;
export const INVALID_INDEX = -1;

export const DEFAULT_MAX_DOSE = 5;
