import { Box, Card, CardBody, Center, Heading, VStack } from '@chakra-ui/react';
import { FiRefreshCw } from 'react-icons/fi';
import { RiErrorWarningFill } from 'react-icons/ri';

import Button from '~sf/components/ui/Button';
import InlineIcon from '~sf/components/ui/InlineIcon';
import Logo from '~sf/components/ui/Logo';

const ERROR_TEXT =
  'Our team has already been notified of this issue and will resolve it as soon as possible.';

const ErrorPage = () => {
  const onRetry = () => {
    window.location.reload();
  };

  return (
    <Center h="100vh" bg="bgAccent" textAlign="center">
      <VStack spacing={10} maxW="600px">
        <Logo maxW="300px" variant="normal" />

        <Card>
          <CardBody>
            <Heading size="md" mt={4} mb={10}>
              An error ocurred, please try again later.
            </Heading>
            <Box mb={10}>{ERROR_TEXT}</Box>
            <Button
              leftIcon={<FiRefreshCw />}
              colorScheme="positive"
              eventName="error-page-retry"
              onClick={onRetry}
            >
              Reload page
            </Button>
          </CardBody>
        </Card>
      </VStack>
    </Center>
  );
};

export const ErrorContent = () => (
  <Box px={4} py={2} bg="bgAccent" color="white" rounded="md">
    <Heading size="md">
      <InlineIcon icon={<RiErrorWarningFill />} /> An error ocurred
    </Heading>
    <Box>{ERROR_TEXT}</Box>
  </Box>
);

export default ErrorPage;
