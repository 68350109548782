import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import Routes from '~sf/components/setup/Routes';
import type { Entitlements } from '~sf/models';
import Dashboard from '~sf/pages/Dashboard';
import LoadingPage from '~sf/pages/LoadingPage';

import { AppRoutes } from './constants';
import renderRoutes from './utils/renderRoutes';
import type { RouteDefinition } from './utils/types';

export type AuthenticatedRoutesProps = {
  entitlements: Entitlements[];
  homeResource: AppRoutes | undefined;
};

const routeDefinitions: RouteDefinition[] = [
  {
    path: '/',
    component: <Dashboard />,
    children: [
      {
        path: AppRoutes.Patients,
        children: [
          {
            path: '',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Patients`)),
          },
          {
            path: 'new',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Patients/Add`)),
          },
          {
            path: ':id/*',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Patient`)),
          },
          {
            path: 'edit/:id',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Patients/Edit`)),
          },
        ],
      },
      {
        path: AppRoutes.Clinics,
        children: [
          {
            path: '',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Clinics`)),
          },
          {
            path: 'new',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Clinics/Add`)),
          },
          {
            path: ':id/*',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Clinics/ClinicView`)),
          },
          {
            path: 'edit/:id',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Clinics/Edit`)),
          },
        ],
      },
      {
        path: AppRoutes.Providers,
        children: [
          {
            path: '',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Providers`)),
          },
          {
            path: 'new',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Providers/Add`)),
          },
          {
            path: ':id/*',
            lazyComponent: lazy(
              async () => import(`../../../pages/Dashboard/Providers/ProviderView`),
            ),
          },
          {
            path: 'edit/:id',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Providers/Edit`)),
          },
        ],
      },
      {
        path: AppRoutes.Staff,
        children: [
          {
            path: '',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Staff`)),
          },
          {
            path: 'new',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Staff/Add`)),
          },
          {
            path: ':id/*',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Staff/StaffView`)),
          },
          {
            path: 'edit/:id',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Staff/Edit`)),
          },
        ],
      },
      {
        path: AppRoutes.Devices,
        children: [
          {
            path: '',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Devices`)),
          },
          {
            path: 'new',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Devices/scan/Add`)),
          },
          {
            path: 'edit/:id',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Devices/scan/Edit`)),
          },
          {
            // eslint-disable-next-line no-restricted-syntax
            path: 'ucsf/new',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Devices/ucsf/Add`)),
          },
          {
            // eslint-disable-next-line no-restricted-syntax
            path: 'ucsf/edit/:id',
            lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Devices/ucsf/Edit`)),
          },
        ],
      },
      {
        path: AppRoutes.PatientHome,
        lazyComponent: lazy(async () => import(`../../../pages/Dashboard/PatientHome`)),
      },
      {
        path: `${AppRoutes.AnnotationJobs}/*`,
        entitlement: 'annotation_portal.list_annotationjobpageresource_entitlement',
        lazyComponent: lazy(async () => import(`../../../pages/CCT`)),
      },
      {
        path: AppRoutes.Contact,
        lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Contact`)),
      },
      {
        path: AppRoutes.About,
        lazyComponent: lazy(async () => import(`../../../pages/Dashboard/About`)),
      },
      {
        path: AppRoutes.EcgReview,
        lazyComponent: lazy(async () => import(`../../../pages/Dashboard/EcgReview`)),
      },
    ],
  },
  {
    path: `${AppRoutes.Patients}/:id/report`,
    entitlement: 'clinic.list_patientpageresource_entitlement',
    lazyComponent: lazy(async () => import(`../../../pages/Dashboard/Patient/PatientReportLoader`)),
  },
];

const AuthenticatedRoutes = ({ entitlements, homeResource }: AuthenticatedRoutesProps) => (
  <Routes>
    {renderRoutes(routeDefinitions, entitlements)}

    <Route path="*" element={<Navigate to={homeResource ?? '/'} replace />} />
    <Route index element={<Navigate to={homeResource ?? '/'} replace />} />

    <Route index element={<LoadingPage />} />
  </Routes>
);

export default AuthenticatedRoutes;
