import * as Sentry from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { entitlementsClient, profileClient } from '~sf/api/clients';
import { QueryKey } from '~sf/api/query';
import { AppRoutes } from '~sf/components/setup/MainRouter/constants';
import { QUERY_REFETCH_TIMEOUT_LONG } from '~sf/constants';
import type {
  DeprecatedPermissionResource,
  Entitlements,
  MedicationDescriptionMap,
} from '~sf/models';
import { isSafari } from '~sf/utils/detectBrowser';
import useIsMobile from '~sf/utils/hooks/useIsMobile';
import useNotify from '~sf/utils/hooks/useNotify';
import useStore from '~sf/utils/hooks/useStore';
import isUndefined from '~sf/utils/isUndefined';

const CheckAuth = () => {
  const { token, authenticate, loggedIn, setEntitlements } = useStore((state) => ({
    token: state.token,
    authenticate: state.authenticate,
    loggedIn: state.loggedIn,
    setEntitlements: state.setEntitlements,
  }));

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isMobile = useIsMobile();
  const notify = useNotify();

  useQuery({
    queryKey: QueryKey.Entitlements,
    queryFn: async () => entitlementsClient({}),
    onSuccess: ({ data }) => {
      setEntitlements(data.permissions as Entitlements[]);
    },
    enabled: loggedIn,
    refetchOnWindowFocus: true,
  });

  useQuery({
    queryKey: QueryKey.Profile,
    queryFn: async () => profileClient({}),
    retry: 1,
    enabled: !isUndefined(token),
    onSuccess: ({ data }) => {
      authenticate(
        {
          token,
          loggedIn: true,
          onlineStatus: true,

          medications: data.drug_desc as MedicationDescriptionMap,
          deprecated_permissions: data.menus as DeprecatedPermissionResource[],
        },
        queryClient,
      );

      // Update Sentry user id
      Sentry.setUser({ id: String(data.id), email: data.email });
    },
    onError: (e) => {
      if (token) {
        notify({
          status: 'info',
          description: 'Your session has expired. Please login again.',
        });
      }
      // not logged in
      authenticate(
        {
          loggedIn: false,
          onlineStatus: !(e instanceof Error && e.message === 'Failed to fetch'),
          medications: {},
          deprecated_permissions: [],
          token: undefined,
        },
        queryClient,
      );

      // TODO: redirect back to here after logging in
      if (loggedIn) {
        navigate(AppRoutes.SignIn);
      }
    },
    // TODO: remove browser check once we fix re-rendering issues with Safari
    refetchInterval: isSafari() || isMobile ? false : QUERY_REFETCH_TIMEOUT_LONG,
    refetchOnWindowFocus: true,
  });

  return null;
};

export default CheckAuth;
