import { Route } from 'react-router-dom';

import ComponentLoader from '~sf/components/setup/ComponentLoader';
import { AppRouteEntitlements, type AppRoutes } from '~sf/components/setup/MainRouter/constants';
import type { Entitlements } from '~sf/models';
import isUndefined from '~sf/utils/isUndefined';

import type { LazyComponentDefinition, RouteDefinition } from './types';

function loadComponent(Component: LazyComponentDefinition) {
  return (
    <ComponentLoader>
      <Component />
    </ComponentLoader>
  );
}

function renderRoutes(definitions: RouteDefinition[], entitlements: Entitlements[] = []) {
  return definitions
    .filter(({ path, entitlement }) => {
      // Some routes specify entitlements
      if (!isUndefined(entitlement)) {
        return entitlements.includes(entitlement);
      }

      // Otherwise we check the default AppRoute entitlements in case we're in one of those
      const routeEntitlement = AppRouteEntitlements[path as AppRoutes];
      if (!isUndefined(routeEntitlement)) {
        return entitlements.includes(routeEntitlement);
      }

      return true;
    })
    .map((def) => {
      const element =
        // eslint-disable-next-line no-nested-ternary
        'component' in def
          ? def.component
          : def.lazyComponent
            ? loadComponent(def.lazyComponent)
            : undefined;

      return (
        <Route key={def.path} path={def.path} element={element}>
          {renderRoutes(def.children ?? [], entitlements)}
        </Route>
      );
    });
}

export default renderRoutes;
