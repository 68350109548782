import { API_URL } from './constants';

const setupMsw = async () => {
  if (import.meta.env.VITE_MOCK_SERVER !== '1') {
    return;
  }

  const mswBrowser = (await import('../__mocks__/mswBrowser')).default;
  const worker = await mswBrowser(API_URL);

  void worker.start({
    serviceWorker: {
      /**
       * Use a custom Service Worker script URL to resolve
       * the mock worker served by Codesandbox.
       * @note You DO NOT need this in your application.
       * @see https://mswjs.io/docs/api/setup-worker/start#serviceworker
       */
      url: '/mockServiceWorker.js',
    },
  });
};

export default setupMsw;
