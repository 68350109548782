import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApiError } from 'openapi-typescript-fetch';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '~sf/components/setup/MainRouter/constants';
import useStore from '~sf/utils/hooks/useStore';

import { MAX_RETRIES, QUERY_CACHE_STALE_TIME, SKIP_RETRIES_HTTP_STATUS } from './constants';

type QueryContextProps = {
  children: React.ReactNode;
};

const QueryContext = ({ children }: QueryContextProps) => {
  const navigate = useNavigate();
  const { authenticate, loggedIn } = useStore((state) => ({
    authenticate: state.authenticate,
    loggedIn: state.loggedIn,
  }));

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            networkMode: 'always',
          },
          queries: {
            networkMode: 'always',
            staleTime: QUERY_CACHE_STALE_TIME,
            retry: (failureCount, error) => {
              if (
                'status' in (error as ApiError) &&
                SKIP_RETRIES_HTTP_STATUS.includes((error as ApiError).status)
              ) {
                return false;
              }
              return failureCount < MAX_RETRIES;
            },
          },
        },
        queryCache: new QueryCache({
          onError: (e) => {
            if (e instanceof ApiError && e.status === 401) {
              // logged out
              authenticate(
                {
                  loggedIn: false,
                  onlineStatus: true,
                  medications: {},
                  deprecated_permissions: [],
                },
                queryClient,
              );
              if (loggedIn) {
                navigate(AppRoutes.SignIn);
              }
            }
          },
        }),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default QueryContext;
