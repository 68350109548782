import type { QueryClient } from '@tanstack/react-query';
import { create } from 'zustand';

import { setAuthToken, TOKEN_STORAGE_KEY, unsetAuthToken } from '~sf/api/fetch';
import type { DeprecatedPermissionResource, Entitlements, MedicationDescription } from '~sf/models';
import isNull from '~sf/utils/isNull';
import isUndefined from '~sf/utils/isUndefined';

export type NotifyType = {
  title?: string;
  message: string;
  status: 'info' | 'success' | 'error';
};

export type State = {
  // Auth
  checkedAuth: boolean;
  loggedIn: boolean;
  onlineStatus: boolean;
  token: string | undefined;
  permissionsChecked: boolean;
  entitlementsChecked: boolean;

  // Profile info

  deprecated_permissions: DeprecatedPermissionResource[];
  entitlements: Entitlements[];
  medications: {
    [key: string]: MedicationDescription;
  };

  authenticate: (
    {
      loggedIn,
      onlineStatus,
      deprecated_permissions,
      medications,
      token,
    }: {
      loggedIn: boolean;
      onlineStatus: boolean;

      deprecated_permissions: DeprecatedPermissionResource[];
      medications: {
        [key: string]: MedicationDescription;
      };
      token?: string;
    },
    queryClient: QueryClient,
  ) => void;

  setEntitlements: (entitlements: Entitlements[]) => void;
};

const storageToken = localStorage.getItem(TOKEN_STORAGE_KEY);
const hasStorageToken =
  !isNull(storageToken) && !isUndefined(storageToken) && storageToken.length > 0;

const useStore = create<State>((set, get) => ({
  token: storageToken ?? undefined,
  checkedAuth: !hasStorageToken,
  loggedIn: false,
  permissionsChecked: false,
  entitlementsChecked: false,
  onlineStatus: true,

  deprecated_permissions: [],
  entitlements: [],
  medications: {},

  // Updates
  authenticate: (data, queryClient) => {
    if (!data) {
      return;
    }

    const wasLoggedIn = get().loggedIn;

    const { token, ...rest } = data;

    set({
      ...rest,
      token,
      checkedAuth: true,
    });

    if (data.deprecated_permissions && data.deprecated_permissions.length > 0) {
      set({
        permissionsChecked: true,
      });
    }

    if (!data.loggedIn) {
      unsetAuthToken();

      if (wasLoggedIn && queryClient) {
        // reset query cache when logging in as it could be a different user from the last time
        queryClient.clear();
      }
    } else if (token) {
      setAuthToken(token);
    }
  },

  setEntitlements: (entitlements) => {
    set({ entitlements, entitlementsChecked: true });
  },
}));

export default useStore;
