// UCSF only
import { fetcher } from '~sf/api/fetch';

export const ucsf_notifyAdminsMedicationTaken = fetcher
  .path('/ucsf_trial/patients/{userid}/notify_admins')
  .method('post')
  .create();

export const ucsf_getPatientDevices = fetcher
  .path('/ucsf_trial/patients/{userid}/devices')
  .method('get')
  .create();
export const ucsf_getPatientAnalysis = fetcher
  .path('/ucsf_trial/patients/{userid}/analysis')
  .method('get')
  .create();
export const ucsf_updatePatientAnalysisClient = fetcher
  .path('/ucsf_trial/patients/{userid}/analysis/{id}')
  .method('put')
  .create();

export const ucsf_analyzeMeasurementClient = fetcher
  .path('/ucsf_trial/devices/{deviceid}/measurements/{id}/analyse')
  .method('get')
  .create();

export const ucsf_getPatientCrClClient = fetcher
  .path('/ucsf_trial/patients/{userid}/crcl')
  .method('get')
  .create();

export const ucsf_insertEcgClient = fetcher
  .path('/ucsf_trial/devices/{deviceid}/insert-ecg')
  .method('get')
  .create();

export const ucsf_getMultipleECGPerDose = fetcher
  .path('/ucsf_trial/patients/{userid}/measurements/ecg/{protocolid}/')
  .method('get')
  .create();

export const ucsf_approveAnalysisClient = fetcher
  .path('/ucsf_trial/patients/{userid}/analysis/{id}/approval')
  .method('post')
  .create();

export const ucsf_updatePatientMedicationClient = fetcher
  .path('/ucsf_trial/patients/{userid}/medication/{medication_id}')
  .method('put')
  .create();
export const ucsf_approveMedicationClient = fetcher
  .path('/ucsf_trial/patients/{userid}/medication/{medication_id}/approval')
  .method('post')
  .create();

export const ucsf_getPatientDosage = fetcher
  .path('/ucsf_trial/patients/{userid}/dosage/{dosageId}')
  .method('get')
  .create();
export const ucsf_updateDosageMeasurement = fetcher
  .path('/ucsf_trial/patients/{userid}/dosage/{doseId}/swap')
  .method('put')
  .create();
export const ucsf_updatePatientDosage = fetcher
  .path('/ucsf_trial/patients/{userid}/dosage/{dosageId}')
  .method('put')
  .create();
export const ucsf_notifyProvidersClient = fetcher
  .path('/ucsf_trial/patients/{userid}/dosage/{doseId}/notify')
  .method('post')
  .create();

export const ucsf_getPatientMeasurements = fetcher
  .path('/ucsf_trial/patients/{userid}/measurements')
  .method('get')
  .create();
export const ucsf_getPatientMeasurement = fetcher
  .path('/ucsf_trial/patients/{userid}/measurements/{measurementId}')
  .method('get')
  .create();

export const ucsf_updateSelectedBeat = fetcher
  .path('/ucsf_trial/patients/{userid}/measurements/{measurementId}')
  .method('put')
  .create();
export const ucsf_postPatientMeasurement = fetcher
  .path('/ucsf_trial/patients/{userid}/measurements')
  .method('post')
  .create();
export const ucsf_getPatientManualMeasurements = fetcher
  .path('/ucsf_trial/patients/{userid}/manualmeasurement')
  .method('get')
  .create();
export const ucsf_getPatientMeasurementNote = fetcher
  .path('/ucsf_trial/patients/{userid}/measurements/{measurementId}/notes')
  .method('get')
  .create();
export const ucsf_postPatientMeasurementNote = fetcher
  .path('/ucsf_trial/patients/{userid}/measurements/{measurementId}/notes')
  .method('post')
  .create();

export const ucsf_getPatientHomeClient = fetcher
  .path('/ucsf_trial/patient/')
  .method('get')
  .create();

export const ucsf_getDrugLoadingProtocolClient = fetcher
  .path('/ucsf_trial/patients/{userid}/medication/dosage')
  .method('get')
  .create();
export const ucsf_updateDrugLoadingProtocolClient = fetcher
  .path('/ucsf_trial/patients/{userid}/medication/dosage')
  .method('put')
  .create();
export const ucsf_initiateDrugLoadingProtocolClient = fetcher
  .path('/ucsf_trial/patients/{userid}/medication/dosage')
  .method('post')
  .create();

export const ucsf_postPatientDoseTaken = fetcher
  .path('/ucsf_trial/patient/dose-taken/{mid}')
  .method('post')
  .create();

// devices
export const ucsf_getDevicesClient = fetcher.path('/ucsf_trial/devices/').method('get').create();

export const ucsf_postDevicesClient = fetcher.path('/ucsf_trial/devices/').method('post').create();
export const ucsf_putDevicesClient = fetcher
  .path('/ucsf_trial/devices/{deviceid}')
  .method('put')
  .create();

export const ucsf_getDeviceClient = fetcher
  .path('/ucsf_trial/devices/{deviceid}')
  .method('get')
  .create();
export const ucsf_syncDeviceClient = fetcher
  .path('/ucsf_trial/devices/{deviceid}/sync')
  .method('get')
  .create();

export const ucsf_deleteDevicesClient = fetcher
  .path('/ucsf_trial/devices/{deviceid}')
  .method('delete')
  .create();

export const ucsf_getNotificationLog = fetcher
  .path('/web-client/notification/log/ucsf_protocol/{protocol_id}/doctor-sms/')
  .method('get')
  .create();
