type NominalType<Brand extends symbol> = { readonly __brand__: Brand };

const UserIdSymbol = Symbol('UserId');
type UserId = NominalType<typeof UserIdSymbol>;

const ClinicIdSymbol = Symbol('ClinicId');
type ClinicId = NominalType<typeof ClinicIdSymbol>;

const ClinicStaffIdSymbol = Symbol('ClinicStaffId');
type ClinicStaffId = NominalType<typeof ClinicStaffIdSymbol>;

const PatientIdSymbol = Symbol('PatientId');
type PatientId = NominalType<typeof PatientIdSymbol>;

const ProtocolIdSymbol = Symbol('ProtocolId');
type ProtocolId = NominalType<typeof ProtocolIdSymbol>;

export type { ClinicId, ClinicStaffId, PatientId, ProtocolId, UserId };

// Conversion Methods between number and nominal types
export const UserIdType = (id: number | UserId): UserId => id as unknown as UserId;
export const ClinicIdType = (id: number | ClinicId): ClinicId => id as unknown as ClinicId;
export const ClinicStaffIdType = (id: number | ClinicStaffId): ClinicStaffId =>
  id as unknown as ClinicStaffId;
export const PatientIdType = (id: number | PatientId): PatientId => id as unknown as PatientId;
export const ProtocolIdType = (id: number | ProtocolId): ProtocolId => id as unknown as ProtocolId;

export const NumberType = (
  id: ClinicId | ClinicStaffId | PatientId | ProtocolId | UserId | number,
): number => id as unknown as number;
